import { Link } from 'react-router-dom';
import { InvestorRequestGet } from 'types';
import UserNavbar from '../../components/Navbar/UserNavbar';
import Table from '../../components/Table';
import TableData from '../../components/Table/TableData';
import TableHeader from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import TableRowHeader from '../../components/Table/TableRowHeader';
import useGetNonTypeSafe from '../../hooks/useGetNonTypeSafe';
import { api } from '../../lib/api';

export default function Forms() {
   const [requests, requestsError] =
      useGetNonTypeSafe<InvestorRequestGet[]>('me/forms');

   const formatStatus = (startedAt: Date | null, completedAt: Date | null) => {
      if (completedAt) {
         return 'Complete';
      }
      if (startedAt) {
         return 'In Progress';
      }
      return 'New';
   };

   return (
      <div className="min-h-screen bg-gray-100">
         <UserNavbar />

         <div className="mx-4 py-20 md:py-24">
            <div className="max-w-5xl mx-auto">
               <div className="pb-1 md:pb-5 sm:flex md:items-center md:justify-between">
                  <h1 className="text-3xl font-bold leading-tight text-gray-900 text-left">
                     Forms
                  </h1>
               </div>

               <div className="max-w-screen-lg w-11/12 mx-auto">
                  <Table>
                     <TableHeader>
                        <TableRowHeader>Form</TableRowHeader>
                        <TableRowHeader>Fund</TableRowHeader>
                        <TableRowHeader>Status</TableRowHeader>
                        <TableRowHeader>Actions</TableRowHeader>
                     </TableHeader>
                     <tbody>
                        {requests?.map(request => (
                           <TableRow key={request.id}>
                              <TableData>
                                 {request.formName}
                                 {request.isFinalSignator ? null : (
                                    <p className="text-gray-500">
                                       {request.investorName ||
                                          request.secondSignature?.name ||
                                          request.secondSignature?.email}
                                    </p>
                                 )}
                              </TableData>
                              <TableData>{request.fundName}</TableData>
                              <TableData>
                                 {formatStatus(
                                    request.startedAt,
                                    request.completedAt,
                                 )}
                              </TableData>
                              <TableData>
                                 {request.completedAt ? (
                                    <DownloadButton requestId={request.id} />
                                 ) : (
                                    <ContinueButton requestId={request.id} />
                                 )}
                              </TableData>
                           </TableRow>
                        ))}
                     </tbody>
                  </Table>
               </div>
            </div>
         </div>
      </div>
   );
}

function DownloadButton({ requestId }: { requestId: number }) {
   return (
      <Link className="action-btn" to={`/form/${requestId}`}>
         Download
      </Link>
   );
}

function ContinueButton({ requestId }: { requestId: number }) {
   return (
      <Link className="action-btn" to={`/form/${requestId}`}>
         Continue
      </Link>
   );
}
